@import '../../marketplace.css';

.pageTitle {
  text-align: center;
  font-size: 36px;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;
  font-size: 16px;
  display: grid;
  grid-template-columns: 20% 45% 35%;
  border-style: solid;
  border-width: 1px;
  border-color: #CCC;

  @media (--viewportMedium) {
    max-width: 950px;
  }
}

.columnTitle {
  font-size: 14px;
  background-color: #DDD;
  padding-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
}

.columnCell {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 5px;
}

.columnCellEven {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 5px;
  background-color: #EEE;
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 48px;
  margin-bottom: 24px;
}
