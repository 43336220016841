@import '../../marketplace.css';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;

  --EditListingAvailabilityForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.title {
  margin-bottom: 24px;
}

.error {
  color: var(--failColor);
}

.calendarWrapper {
  flex-grow: 1;
  position: relative;
  width: 100%;
  margin-bottom: 24px;
}

.numberOfUnits {
  @apply --EditListingAvailabilityForm_formMargins;
  flex-shrink: 0;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
}

@media (--viewportMedium) {
  .root {
    padding-top: 2px;
    margin-top: -16px;
  }
  .title {
    margin-bottom: 32px;
  }
}

@media (--viewportLarge) {
  .calendarWrapper {
    flex-grow: 0;
    margin-bottom: 24px;
  }
  .submitButton {
    display: inline-block;
    width: 241px;
    margin-top: 86px;
  }
}
